
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        


























.packaging {
}

.packaging__picture {
  max-width: 350px;
  width: 100%;
}

.packaging__image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 120%;

  div {
    @include get-all-space;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .p1 {
    width: 60%;
    z-index: 10;
    margin-left: -8%;
  }

  .p2 {
    width: 45%;
    margin-top: -34%;
    margin-left: 22%;
  }

  .p3 {
    width: 20%;
    z-index: 20;
    margin-left: 38%;
    margin-top: 40%;
  }

  .p4 {
    width: 11%;
    z-index: 20;
    margin-left: -40%;
    margin-top: 47%;
  }

  .p5 {
    width: 10%;
    z-index: 20;
    margin-left: 32%;
    margin-top: -50%;
  }
}

.packaging__headline {
  @include fluid(
    font-size,
    (
      mob: 80px,
      xxl: 240px,
    )
  );

  line-height: 1;
  -webkit-text-stroke: 1px $black;
  -webkit-text-fill-color: transparent;

  @include mq(m) {
    -webkit-text-stroke: 2px $black;
  }
}

.packaging__title {
  @include fluid(
    font-size,
    (
      mob: 28px,
      xxl: 48px,
    )
  );

  line-height: 1.1;
}

@include mq(s) {
  .packaging {
    position: relative;
  }

  .packaging__image {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    top: 55%;
    width: 37%;
  }

  .packaging__content {
    position: relative;
    z-index: 20;
    width: 70%;
  }
}

@include mq(m) {
  .packaging__content {
    width: 50%;
  }
}

@include mq(l) {
  .packaging__content {
    width: 40%;
  }
}
