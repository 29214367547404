
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        




















































.fairtrade__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 40%;

  img {
    @include image-fit;

    top: auto;
    height: 110%;
  }
}

.fairtrade__box {
  position: relative;
  text-align: center;
}

.fairtrade__layer {
  @include get-all-space;

  background: $persian-green;

  img {
    @include image-fit;

    opacity: 0.7;
  }
}

.fairtrade__content {
  position: relative;

  @include mq(m) {
    padding-bottom: 100px;
  }
}

.fairtrade__image img,
.fairtrade__layer {
  opacity: 0;
}

.fairtrade__headline,
.fairtrade__title,
.fairtrade__text ::v-deep div,
.fairtrade__link,
.fairtrade__logo {
  opacity: 0;
  transform: translateY(100px);
}

.fairtrade__headline {
  // prettier-ignore
  @include fluid(font-size, (mob: 28px, xxl: 64px));

  color: $white;
  line-height: 1;
}

.fairtrade__title {
  // prettier-ignore
  @include fluid(font-size, (mob: 60px, xxl: 240px));
  // prettier-ignore
  @include fluid(margin-bottom, (mob: -50px, xl: -140px));

  color: $white;
  line-height: 1;

  -webkit-text-stroke: 1px $white;
  -webkit-text-fill-color: transparent;

  @include mq(m) {
    -webkit-text-stroke: 2px $white;
  }
}

.fairtrade__text {
  width: 100%;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.fairtrade__conent__header {
  transform: translateY(-17vw);

  @include mq(s) {
    transform: translateY(-15vw);
  }

  @include mq(l) {
    transform: translateY(-190px);
  }
}

.fairtrade__logo {
  // prettier-ignore
  @include fluid(right, (mob: 30px, xxl: 80px));
  // prettier-ignore
  @include fluid(width, (mob: 60px, xxl: 122px));
  // prettier-ignore
  @include fluid(height, (mob: 80px, xxl: 162px));

  position: absolute;
  right: 30px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;

  img {
    // prettier-ignore
    @include fluid(height, (mob: 32px, xxl: 73px));
  }
}
