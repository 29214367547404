
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        















































































.value__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 60%;

  img {
    @include image-fit;

    top: auto;
    height: calc(100% + 100px);
    transform: scale(1.1);
  }
}

.value__box {
  position: relative;
  overflow: hidden;
  color: $white;
}

.value__layer {
  @include get-all-space;

  background: $tango;
  opacity: 0;

  img {
    width: 150%;
    max-width: none;
    margin-top: -10%;
    margin-left: -5%;
    opacity: 0.7;
  }

  .yellow & {
    img {
      opacity: 1;
    }
  }
}

.value__content {
  position: relative;
  z-index: 10;

  h3 {
    // prettier-ignore
    @include fluid(font-size, (mob: 28px, xl: 48px));

    line-height: 1.2;
  }

  h2 {
    // prettier-ignore
    @include fluid(font-size, (mob: 100px, xl: 250px));

    line-height: 1;
    margin-top: -7px;
    -webkit-text-stroke: 1px $white;
    -webkit-text-fill-color: transparent;

    @include mq(m) {
      -webkit-text-stroke: 2px $white;
    }
  }
}

.value__text,
.value__content__headline {
  ::v-deep div {
    opacity: 0;
    transform: translateY(100px);
  }
}

.value__text,
.value__content__headline {
  ::v-deep div {
    opacity: 0;
    transform: translateY(100px);
  }
}

.value__content__link {
  opacity: 0;
  transform: translateY(100px);
}

.value__content__title {
  opacity: 0;
}

.value__text {
  &::v-deep {
    p + p {
      margin-bottom: 2rem;
    }
  }
}

.value.yellow {
  .value__box {
    color: $black;
  }

  .value__layer {
    background: $school-bus-yellow;
  }

  .value__content h2 {
    -webkit-text-stroke: 1px $black;

    @include mq(m) {
      -webkit-text-stroke: 2px $black;
    }
  }
}

@include mq(s) {
  .value {
    position: relative;
    height: 100vh;
    min-height: 1100px;
  }

  .value__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .value__layer {
    width: 60%;
  }

  .value__image {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 60%;
    width: 40%;
    height: 80%;
    transform: translateY(-50%);
    padding-bottom: 0;
  }

  .value__content {
    width: 60%;
    max-width: 420px;

    h2 {
      // prettier-ignore
      @include fluid(margin-left, (s: -40px, xl: -90px));

      @include mq(xxxl) {
        margin-left: -100px;
      }
    }
  }
}

.value__breakcontainer {
  position: relative;
  z-index: 20;
  width: 100%;
}

.value__break {
  // prettier-ignore
  @include fluid(margin-left, (xxs: 50px, xl: 300px));

  position: relative;
  bottom: 3rem;
  display: flex;
  align-items: flex-end;
  width: 90%;
  max-width: 1030px;
  margin: 0 auto;
  margin-top: -52px;
  margin-bottom: 140px;

  img {
    width: 50%;
    opacity: 0;
  }

  .p11,
  .p12 {
    @include mq(s) {
      opacity: 0;
      transform: translateY(200px);
    }
  }

  .p11 {
    // prettier-ignore
    @include fluid(margin-left, (mob: 20px, xl: 100px));
    // prettier-ignore
    @include fluid(max-width, (mob: 150px, xl: 350px));
  }

  .p12 {
    // prettier-ignore
    @include fluid(max-width, (mob: 300px, xl: 500px));

    margin-bottom: 10rem;
  }

  @include mq(s) {
    position: absolute;
    // top: -200px;
    left: 50%;
    width: 80%;
    margin-bottom: 0;
    transform: translateX(-50%);
  }

  @include mq(l) {
    img {
      width: 50%;
      max-width: 50rem;
    }
  }
}

@include mq(l) {
  .value__image {
    left: 55%;
    width: 40%;
    max-width: 560px;
    height: auto;

    img {
      position: static;
    }
  }
}
