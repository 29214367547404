
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        






































































.root {
  ::v-deep {
    .item {
      opacity: 0;
    }
  }
}

.inspiration__photos {
  position: relative;
  overflow: hidden;
  height: 190vw;

  div {
    position: absolute;
    z-index: 10;

    img {
      @include image-fit;

      opacity: 0;
      transform: translateY(80%);
    }
  }

  .p1 {
    top: 10vw;
    left: 14vw;
    width: 100vw;
    height: 63vw;
  }

  .p2 {
    top: 86vw;
    left: 60vw;
    width: 45vw;
    height: 29vw;
  }

  .p3 {
    top: 98vw;
    left: -13vw;
    width: 50vw;
    height: 38vw;
  }

  .p4 {
    top: 150vw;
    left: 33vw;
    width: 47vw;
    height: 34vw;
  }
}

@include mq(s) {
  .inspiration {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 1400px;
  }

  .inspiration__photos {
    @include get-all-space;

    height: auto;

    > div {
      /* stylelint-disable declaration-no-important */
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: 50vh;
      height: auto;
      /* stylelint-enable declaration-no-important */
    }

    .p1 {
      width: 900px;
      height: 510px;
      margin-top: -250px;
      margin-left: 670px;
    }

    .p2 {
      z-index: 2;
      width: 440px;
      height: 300px;
      margin-top: -440px;
      margin-left: -140px;
    }

    .p3 {
      width: 490px;
      height: 280px;
      margin-top: -330px;
      margin-left: -750px;
    }

    .p4 {
      width: 460px;
      height: 310px;
      margin-top: 230px;
      margin-left: -450px;
    }
  }

  .inspiration__content {
    position: relative;
    z-index: 20;
    max-width: 500px;
  }

  .inspiration__wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
