
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        


























.welcome {
  // prettier-ignore
  @include fluid(padding-top, (mob: 175px, xl: 315px));

  position: relative;
  text-align: center;
}

.welcome__pieces {
  img {
    position: absolute;
    z-index: 3;
    left: 50%;
    filter: none;
    transform: translate(-50%, 300px);
    opacity: 0;
  }

  .p1 {
    // prettier-ignore
    @include fluid(width, (mob: 118px, xl: 245px));
    // prettier-ignore
    @include fluid(top, (mob: 100px, xl: 240px));
    // prettier-ignore
    @include fluid(margin-left, (mob: -170px, xl: -520px));

    transform: translate(-50%, 300px) rotate(30deg);
  }

  .p2 {
    // prettier-ignore
    @include fluid(width, (mob: 32px, xl: 67px));
    // prettier-ignore
    @include fluid(top, (mob: 65px, xl: 180px));
    // prettier-ignore
    @include fluid(margin-left, (mob: -130px, xl: -400px));
  }

  .p3 {
    // prettier-ignore
    @include fluid(width, (mob: 44px, xl: 105px));
    // prettier-ignore
    @include fluid(top, (mob: 420px, xl: 750px));
    // prettier-ignore
    @include fluid(margin-left, (mob: -160px, xl: -470px));

    z-index: 5;
  }

  .p4 {
    // prettier-ignore
    @include fluid(width, (mob: 30px, xl: 70px));
    // prettier-ignore
    @include fluid(top, (mob: 405px, xl: 745px));
    // prettier-ignore
    @include fluid(margin-left, (mob: -180px, xl: -520px));
  }

  .p5 {
    // prettier-ignore
    @include fluid(width, (mob: 124px, xl: 288px));
    // prettier-ignore
    @include fluid(top, (mob: 495px, xl: 900px));
    // prettier-ignore
    @include fluid(margin-left, (mob: -40px, xl: -180px));

    transform: translate(-50%, 300px) rotate(30deg);
  }

  .p6 {
    // prettier-ignore
    @include fluid(width, (mob: 60px, xl: 105px));
    // prettier-ignore
    @include fluid(top, (mob: 530px, xl: 930px));
    // prettier-ignore
    @include fluid(margin-left, (mob: 100px, xl: 100px));
  }

  .p7 {
    // prettier-ignore
    @include fluid(width, (mob: 135px, xl: 270px));
    // prettier-ignore
    @include fluid(top, (mob: 300px, xl: 630px));
    // prettier-ignore
    @include fluid(margin-left, (mob: 230px, xl: 550px));

    z-index: 4;
    transform: translate(-50%, 300px) rotate(-30deg);
  }

  .p8 {
    // prettier-ignore
    @include fluid(width, (mob: 32px, xl: 64px));
    // prettier-ignore
    @include fluid(top, (mob: 300px, xl: 620px));
    // prettier-ignore
    @include fluid(margin-left, (mob: 180px, xl: 470px));
  }

  .p9 {
    // prettier-ignore
    @include fluid(width, (mob: 53px, xl: 105px));
    // prettier-ignore
    @include fluid(top, (mob: 270px, xl: 560px));
    // prettier-ignore
    @include fluid(margin-left, (mob: 200px, xl: 570px));

    z-index: 6;
  }
}

.welcome__title {
  // prettier-ignore
  @include fluid(font-size, (mob: 28px, xl: 48px));
  @include manier;

  font-weight: 300;
  line-height: 1.02;
  opacity: 0;

  &.h1 {
    @extend %visually-hidden;
  }

  ::v-deep > div {
    transform: translateY(300px);
  }

  &::v-deep strong {
    // prettier-ignore
    @include fluid(font-size, (mob: 36px, xl: 84px));

    display: block;
    margin-bottom: 4rem;
    font-weight: 700;
  }
}

.welcome__baseline {
  max-width: 400px;
  margin: 0 auto;
  transform: translateY(300px);
  opacity: 0;
}

.welcome__baseline::v-deep p {
  // prettier-ignore
  @include fluid(font-size, (mob: 18px, xl: 24px));

  font-weight: 300;
}
