
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        














































.text-image,
[class*='text-image--'] {
  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }

  @include mq(s) {
    display: flex;
  }
}

.picture {
  position: relative;
  flex-shrink: 0;
  flex-basis: calc(50% - #{inside-col(1)});
  overflow: hidden;
  max-height: 30rem;
  margin-bottom: 2.5rem;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    @include image-fit;

    top: auto;
    height: calc(100% + 70px);
    will-change: transform;
  }

  @include mq(s) {
    max-height: none;
    margin-right: inside-col(3);
    margin-bottom: 0;

    [class*='text-image--'][class*='--reverse'] & {
      margin-right: 0;
      margin-left: inside-col(3);
    }
  }
}

.title {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 10px, m: 15px));
}

.content {
  flex: 1;
  opacity: 0;

  h2 {
    @extend %visually-hidden;
  }

  @include mq(s) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.subtitle {
  @include manier;
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 13px, m: 15px));

  display: block;
  font-weight: 900;
}

.text {
  margin-top: 2.5rem;
  margin-bottom: 3rem;

  @include mq(s) {
    margin-bottom: 6rem;
  }
}
