
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        








































.promo {
  position: relative;
}

.promo__pattern {
  @include get-all-space;

  right: auto;
  width: calc(100% + 130px);
  background-repeat: repeat;
  background-size: 130px;
  transform: translateX(-130px);

  .is-visible & {
    animation: scroll 4s linear infinite;
  }
}

@keyframes scroll {
  to {
    transform: translateX(0);
  }
}

.promo__image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%;

  img {
    @include image-fit;
  }
}

.promo__category {
  display: none;
}

.promo__box {
  position: relative;
  margin-top: -50px;
  padding: 20px 30px;
  background: $white;
}

.promo__title {
  // prettier-ignore
  @include fluid(font-size, (m: 24px, xl: 48px));

  line-height: 1;

  ::v-deep p {
    @include manier;

    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }
}

.promo__text {
  &::v-deep {
    p {
      font-weight: 700;
    }
  }
}

@include mq(m) {
  .promo {
    // prettier-ignore
    @include fluid(padding-bottom, (m: 80px, xl: 110px));
  }

  .promo__image {
    padding-bottom: 53%;
  }

  .promo__pattern {
    top: 40%;
    height: auto;
  }

  .promo__inner {
    position: relative;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
  }

  .promo__box {
    // prettier-ignore
    @include fluid(left, (m: 40px, xl: 70px));
    // prettier-ignore
    @include fluid(bottom, (m: 70px, xl: 140px));

    position: absolute;
    padding: 0;
    background: transparent;

    .category {
      display: none;
    }
  }

  .promo__category {
    // prettier-ignore
    @include fluid(left, (m: 40px, xl: 70px));
    // prettier-ignore
    @include fluid(top, (m: 40px, xl: 80px));

    position: absolute;
    z-index: 10;
    display: block;
  }
}
